html,
#root,
body,
.App {
	font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
	color: #333;
	min-width: 465px;
	width: 100vw;
	overflow-x: hidden;
}

:root {
	--green-primary: #0e8a44;
	--green-secondary: #138f493d;
	--green-disabled: #0e8a4485;
	--green-lighter: #0e8a4417;
	--button-text: white;
	--button-text-secondary: rgb(255, 255, 255);
}

/* APP CONTAINER */
.app__container {
	margin-left: auto;
	margin-right: auto;
	max-width: 1300px;
}

.app__container-iframed {
	max-width: 1100px;
}

/* BODY CONTAINER */
.app__container-body {
	padding: 1rem 6.2rem 1rem 6.2rem;
}

.app__container-body-iframed {
	padding: 30px 10px 30px 30px;
}

.MuiAutocomplete-root .MuiAutocomplete-clearIndicator{
	display:none;
}

.MuiAutocomplete-root[aria-expanded="true"] .MuiOutlinedInput-adornedStart .MuiAutocomplete-clearIndicator {
	display: block;
}


.MuiAutocomplete-root[aria-expanded="true"] .MuiOutlinedInput-adornedStart .MuiAutocomplete-endAdornment {
	display: flex;
	flex-direction: column;
    top: calc(50% - 25px);
	margin: auto;
	bottom: 0px;
	align-self: center;
}

/* Jobs Page Container  */
.app__container-jobs {
	padding: 1rem 6.2rem 1rem 6.2rem;
}

.app__container-jobs-iframed {
	padding: 30px 10px 30px 30px;
}

/* NAVBAR */
.navbar {
	display: flex;
	align-items: center;
	border-radius: 4px;
	flex-wrap: wrap;
	padding: 5px 40px 0px 30px;
}

.navbar-iframed {
	display: flex;
	align-items: center;
	border-radius: 4px;
	flex-wrap: wrap;
	padding: 5px 10px 0px 30px;
}

@media screen and (max-width: 750px) {
	.app__container-body {
		padding: 1rem 2rem 1rem 1rem !important;
	}
}

@media screen and (max-width: 465px) {
	html,
	#root,
	body,
	.App {
		overflow-x: auto;
	}
}

.navbar__link {
	margin: 1em;
	font-family: sans-serif;
	cursor: pointer;
	letter-spacing: 0.1em;
	text-indent: 0.3em;
	border-bottom: 3px solid transparent;
	font-size: 1rem;
	padding: 0.625rem 0rem 0.3125rem 0rem;
}

.navbar-brand {
	display: flex;
	align-items: center;
	padding-top: 0.4rem;
	padding-bottom: 0.4rem;
	margin-right: 1rem;
	font-size: 1.25rem;
	line-height: inherit;
	white-space: nowrap;
}
.navbar__link,
.navbar__link:hover {
	color: #181818;
	text-decoration: none;
}

.navbar__link--active {
	border-bottom: 3px solid var(--green-primary);
	transition: border-bottom 0.2s ease-in-out;
}

.navbar__link:hover {
	transform: translateY(-2px);
	color: #181818;
	text-decoration: none;
}

section {
	margin-bottom: 2rem;
}

.form-group {
	margin-top: 1rem !important;
}

.file-validation-phrase {
	font-size: 17px !important;
	color: red;
	white-space: pre;
}
.file-validation-message {
	margin-top: 0.5rem !important;
	margin-bottom: 1rem !important;
	color: red;
	white-space: pre;
}

/* Dropdown Menus & Label */
.MuiFormLabel-root.Mui-focused {
	color: var(--green-primary) !important;
}

.Mui-focused > .MuiOutlinedInput-notchedOutline {
	border-color: var(--green-primary) !important;
}

/* Input Box */
.MuiInput-underline::after {
	border-bottom-color: var(--green-primary) !important;
}

/* Buttons Green and Disabled */
.MuiButtonBase-root.MuiButton-root.MuiButton-contained {
	background-color: var(--green-primary) !important;
	color: var(--button-text) !important;
}
.MuiButtonBase-root.MuiButton-root.MuiButton-contained.Mui-disabled {
	background-color: var(--green-disabled) !important;
	color: var(--button-text) !important;
}

/* Spinners*/
.MuiCircularProgress-root {
	color: var(--green-primary) !important;
}
/* Spinners in green buttons */
.MuiButtonBase-root.MuiButton-root.MuiButton-contained
	.MuiCircularProgress-root {
	/* color: var(--button-text) !important; */
	color: var(--button-text) !important;
}

/* Toggle Buttons */
.MuiToggleButton-root {
	color: rgba(0, 0, 0, 0.75) !important;
	border: 1px solid rgba(0, 0, 0, 0.2) !important;
}
.MuiToggleButton-root.Mui-selected {
	color: rgba(0, 0, 0, 1) !important;
}

.table-status-row {
	display: flex;
	align-items: center;
	pointer-events: none;
}

.table-status-image {
	margin-right: 10px;
}

.warning-icon {
	color: #c43c01;
}

.completed-icon {
	color: #0e8a44;
}

.failed-icon {
	color: #ae0112;
}

.processing-icon {
	color: #2096f3 !important;
}

/* Split Buttons */
.MuiButtonGroup-groupedContainedPrimary:not(:last-child) {
	border-color: var(--button-text) !important;
}

/* Checkbox */
.MuiCheckbox-colorPrimary {
	color: var(--green-primary) !important;
}

.MuiCheckbox-colorPrimary.Mui-disabled {
	color: rgba(0, 0, 0, 0.26) !important;
}

/* Pills */
.pill-selected {
	border-color: var(--green-primary) !important;
	background-color: var(--green-secondary) !important;
}
/* Pill Inner Icon (checkmark) */
.pill-selected > .MuiSvgIcon-root.MuiChip-icon {
	color: var(--green-primary) !important;
}

.excel-documentation-link {
	text-decoration: none;
}

.excel-documentation-link:hover {
	text-decoration: underline;
}

/* Borderless Accordion */
.accordion-borderless {
	border: 0px !important;
	margin: 0px !important;
}
.accordion-borderless::before {
	height: 0px !important;
}
.accordion-borderless > .MuiAccordionSummary-root {
	display: inline-flex !important;
	padding: 0px !important;
}

/* Paragraphs */
.MuiTypography-root {
	white-space: pre-wrap;
}

.MuiChip-root {
	color: white !important;
	background-color: #0e8a44 !important;
}

.MuiChip-colorPrimary {
	background-color: #c43c01 !important;
}

.MuiChip-colorSecondary {
	background-color: #ae0112 !important;
}

/* Hidden Buttons */
.button-hidden {
	visibility: hidden;
}

/* Linear Pogress Bars */
.progress-linear {
	background-color: var(--green-secondary) !important;
}

.progress-linear > .MuiLinearProgress-barColorPrimary {
	background-color: var(--green-primary) !important;
}

/* Quantity Dialog */
.quantity-dialog-containter {
	position: absolute;
	user-select: none;
	left: 0px;
}
.quantity-dialog {
	border: 1px solid #aaa;
	min-width: 200px;
	position: absolute;
	z-index: 2;
	padding: 1rem;
}
.incremental-button {
	border: 1px solid var(--green-primary) !important;
	color: var(--green-primary) !important;
}

.MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon
	.MuiAutocomplete-inputRoot[class*='MuiOutlinedInput-root'] {
	padding-right: 25px !important;
}

.inline-icon {
	vertical-align: bottom;
	font-size: 18px !important;
}

.align-center {
	display: flex;
	align-items: center;
}

.align-center > p {
	margin: 0;
}
